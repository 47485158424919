














































































































































































































import { Vue, Component } from "vue-property-decorator";
import { RoadLink, RouteRoadSearch, RouteRoad } from "@/store/models/meta";
import { Route } from "@/store/models/route";
import metaStore from "@/store/modules/meta";
import { AdminRouter } from "@/utils/routePathContsant";
import draggable from "vuedraggable";

@Component({
  components: {
    draggable,
  },
})
export default class AddRoadLinksView extends Vue {
  route_name_title: Route = {} as Route;
  route_name: string = "";
  selectedRoadLinks: any = [];
  roadLinkList: RoadLink[] = [];
  routeRoadList: RouteRoad[] = [];

  isHidden: Boolean = true;
  isRouteSelect: Boolean = true;

  selectedRoute = "";
  selectRoute: Route[] = [];

  params: RouteRoadSearch = {} as RouteRoadSearch;
  searchedRoadLinks: RouteRoad[] = [];
  isRoadLinkSearched: Boolean = false;

  checkedRoadLinks: any = [];

  finalselectedRoadLinks: RoadLink[] = [];

  async created() {
    const id: any = this.$route.query["id"];

    await metaStore.getRouteById(id);
    this.route_name_title = metaStore.route;

    await metaStore.loadRoutesDD();
    this.selectRoute = metaStore.routesList;
    const route_name: any = metaStore.routesList.find(
      (e) => e.gid === parseInt(id)
    );
    this.route_name = route_name.name;

    await metaStore.getRoadLink();
    this.roadLinkList = metaStore.roadLinkList;

    if (id) {
      this.params.route = parseInt(id);
      await metaStore.getRouteRoad(this.params);
      const list = metaStore.routeRoadList.sort((a, b) => a.id - b.id);
      this.routeRoadList = list;
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  roadLinkRemoteMethod(query: any) {
    if (query !== "") {
      setTimeout(() => {
        this.roadLinkList = metaStore.roadLinkList.filter((item) => {
          return (
            item.roadLinkName.toLowerCase().indexOf(query.toLowerCase()) > -1
          );
        });
      }, 200);
    } else {
      this.roadLinkList = metaStore.roadLinkList;
    }
  }

  onRouteSearch() {
    this.isRouteSelect = !this.isRouteSelect;
  }

  routeRoadRemoteMethod(query: any) {
    if (query !== "") {
      setTimeout(() => {
        this.selectRoute = metaStore.routesList.filter((item) => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }, 200);
    } else {
      this.selectRoute = metaStore.routesList;
    }
  }

  async onRoadLinkSearch() {
    const id: any = this.selectedRoute;
    this.params.route = parseInt(id);
    await metaStore.getRouteRoad(this.params);
    this.searchedRoadLinks = metaStore.routeRoadList;
    this.isRoadLinkSearched = true;
  }

  async onRoadLinkClear() {
    this.searchedRoadLinks = [];
    this.selectedRoute = "";
    this.isRoadLinkSearched = false;
    this.checkedRoadLinks = [];
  }

  async onConfirmRoadLinks() {
    const isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    const totalRoadLinks = this.selectedRoadLinks.concat(this.checkedRoadLinks);
    const result = metaStore.roadLinkList.filter(
      (e) => totalRoadLinks.indexOf(e.gid) > -1
    );
    this.isHidden = false;
    this.finalselectedRoadLinks = result;
  }

  async onCreate() {
    const isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    if (this.finalselectedRoadLinks.length <= 0) {
      this.$snotify.error("Please select RoadLinks before creating");
      return;
    }
    const id: any = this.$route.query["id"];
    const createList: RouteRoad[] = [];
    for (let x of this.finalselectedRoadLinks) {
      const routeRoad: RouteRoad = {} as RouteRoad;
      routeRoad.route = id;
      routeRoad.roadNetwork = x.gid;
      createList.push(routeRoad);
    }
    await metaStore.createRouteRoad(createList);
    this.$router.push(AdminRouter.RouteInfo);
  }

  onClear() {
    this.finalselectedRoadLinks = [];
    this.selectedRoadLinks = [];
    this.onRoadLinkClear();
  }
}
